import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

document.addEventListener('DOMContentLoaded', () => {
  // Mobile menu
  //----------------------------------------------------------
  const mobileMenu = document.getElementById('mobile-menu')
  const openMobileMenuButton = document.getElementById('open-mobile-menu')
  const closeMobileMenuButton = document.getElementById('close-mobile-menu')
  openMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.add('mobile-menu-is-open')
      disableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )
  closeMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.remove('mobile-menu-is-open')
      enableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )

  // Mobile dropdown menu
  //----------------------------------------------------------
  const mobileDropdownItems = [
    ...document.querySelectorAll('.mobile-menu .menu-item-has-children > a')
  ]
  const handleMobileDropdown = (event) => {
    const listItem = event.currentTarget.parentNode
    if (listItem.classList.contains('submenu-is-open')) {
      listItem.classList.remove('submenu-is-open')
    } else {
      mobileDropdownItems.forEach((item) => {
        item.parentNode.classList.remove('submenu-is-open')
      })
      listItem.classList.add('submenu-is-open')
    }
    event.preventDefault()
  }
  mobileDropdownItems.forEach((link) => {
    link.addEventListener('touchstart', handleMobileDropdown, { passive: false })
  })
})
