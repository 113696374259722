/* eslint-disable no-undef */
import '../styles/main.scss'

import hoverintent from 'hoverintent'
import './swiper-bundle'
import './mobile-menu'
import './phone-menu'

document.addEventListener('DOMContentLoaded', function domLoaded() {
  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  // Dropdown menus
  //----------------------------------------------------------
  const menuElements = [...document.querySelectorAll('.menu-item-has-children')]
  const menuLinkElements = [...document.querySelectorAll('.menu-item-has-children > a')]

  const openSubmenu = (element) => {
    element.classList.add('submenu-is-open')
  }
  const closeSubmenu = (element) => {
    element.classList.remove('submenu-is-open')
  }

  // Open & close menu on regular devices
  if (!isTouch) {
    document.body.classList.add('no-touch')
    menuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      )
    })
    // Prevent links on parents
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkParentClick(event) {
        event.preventDefault()
      })
    })
  }

  // Open & close menu on touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkClick(event) {
        menuElements.forEach((node) => {
          node.classList.remove('submenu-is-open')
        })
        element.parentNode.classList.add('submenu-is-open')
        event.preventDefault()
      })
    })
  }

  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.swiper-container', {
    // Optional parameters
    loop: true,
    centeredSlides: true,
    speed: 2500,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
})
