import hoverintent from 'hoverintent'

document.addEventListener('DOMContentLoaded', () => {
  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  // Phone menus
  //----------------------------------------------------------
  const phoneButtons = [...document.querySelectorAll('.phone-button-wrapper')]
  const phoneButtonLinks = [...document.querySelectorAll('.phone-popup-link')]

  const openPhoneMenu = (element) => {
    element.classList.add('phonemenu-is-open')
  }

  const closePhoneMenu = (element) => {
    console.log('close')
    element.classList.remove('phonemenu-is-open')
  }

  // Regular devices
  const opts = {
    timeout: 250,
    interval: 50
  }

  if (!isTouch) {
    phoneButtons.forEach((element) => {
      hoverintent(
        element,
        () => {
          openPhoneMenu(element)
        },
        () => {
          closePhoneMenu(element)
        }
      ).options(opts)
    })
  }

  // Touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    phoneButtons.forEach((element) => {
      element.addEventListener('click', () => {
        openPhoneMenu(element)
      })
    })
    phoneButtonLinks.forEach((element) => {
      element.addEventListener('click', (event) => {
        const wrapperElement = element.parentElement.parentElement
        closePhoneMenu(wrapperElement)
        event.stopPropagation()
      })
    })
  }
})
